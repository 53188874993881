import React, { useState, useEffect, useRef } from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { menuData } from "../../data/menuData"
import MenuButton from "../buttons/MenuButton"
import MenuTooltip from "../tooltips/MenuTooltip"

function Header() {
  const [isOpen, setIsOpen] = useState(false)
  const ref = useRef()
  const tooltipRef = useRef()
  function handleClick(event) {
    setIsOpen(!isOpen)
    event.preventDefault()
  }
  function handleClickOutside(event) {
    if (
      ref.current &&
      !ref.current.contains(event.target) &&
      !tooltipRef.current.contains(event.target)
    ) {
      setIsOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside)

    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [])
  return (
    <Wrapper>
      <Link to="/">
        <img src="/images/icons/weizhengicon.svg" />
      </Link>
      <MenuWrapper count={menuData.length} ref={ref}>
        {menuData.map((item, index) =>
           item.link === "/Design" ? (
            <MenuButton
              key={index}
              item={item}
              // onClick={event => handleClick(event)}
            />
          ) : (
            <MenuButton key={index} item={item} />
          )
        )}
      </MenuWrapper>
      <HamburgerWrapper>
        <MenuButton
          item={{
            title: "",
            icon: "/images/icons/hamburger.svg",
            link: "/",
          }}
          onClick={event => handleClick(event)}
        />
      </HamburgerWrapper>
      <div ref={tooltipRef}>
        <MenuTooltip isOpen={!isOpen} />
      </div>
    </Wrapper>
  )
}
export default Header

const Wrapper = styled.div`
  position: absolute;
  top: 60px;
  display: grid;
  width: 100%;
  justify-items: center;
  grid-template-columns: 44px auto;
  padding: 0px 30px;
  align-items: center;
  @media (max-width: 768px) {
    /* grid-template-columns: repeat(${props => props.count}, auto); */
    top: 60px;
  }
  @media (max-width: 450px) {
    top: 30px;
    padding: 0 20px;
  }
`
const MenuWrapper = styled.div`
  max-width: 800px;
  display: grid;
  grid-template-columns: repeat(${props => props.count}, auto);
  gap: 30px;
  @media (max-width: 768px) {
    /* grid-template-columns: repeat(${props => props.count - 1}, auto);
    > a:nth-child(${props => props.count}) {
      display: none;
    } */
    grid-template-columns: 44px auto;
    > a {
      display: none;
    }
  }
`
const HamburgerWrapper = styled.div`
  display: none;
  @media (max-width: 768px) {
    position: absolute;
    right: 20px;
    display: block;
  }
`
