export const menuData = [
  { title: "Design", icon: "/images/icons/courses.svg", link: "/page-2" },
  {
    title: "Code",
    icon: "/images/icons/keyboard.svg",
    link: "/Code",
  },
  { title: "Idea", icon: "/images/icons/pricing.svg", link: "/Idea" },
  // { title: "", icon: "/images/icons/search.svg", link: "/search" },
  // { title: "", icon: "/images/icons/account.svg", link: "/account" },
]
export const tooltipData = [
  // { title: "Profile", icon: "/images/icons/profile.svg", link: "/profile" },
  // { title: "Settings", icon: "/images/icons/settings.svg", link: "/settings" },
  // { title: "Sign out", icon: "/images/icons/signout.svg", link: "/logout" },
  { title: "Design", icon: "/images/icons/courses.svg", link: "/page-2" },
  {
    title: "Code",
    icon: "/images/icons/keyboard.svg",
    link: "/Code",
  },
  { title: "Idea", icon: "/images/icons/pricing.svg", link: "/Idea" },
]
export const cardData = [
  {
    title: "DesignSystem",
    bg: "/images/animations/gcard.svg",
    icon: "/images/design/work1.svg",
    link: "/page-2",
  },
  {
    title: "DesignSystem",
    bg: "/images/animations/gcard.svg",
    icon: "/images/design/work4.gif",
    link: "/page-2",
  },
  {
    title: "DesignSystem",
    bg: "/images/animations/gcard.svg",
    icon: "/images/design/work3.gif",
    link: "/page-2",
  },
  {
    title: "DesignSystem",
    bg: "/images/animations/gcard.svg",
    icon: "/images/animations/ill10.svg",
    link: "/page-2",
  },
  {
    title: "DesignSystem",
    bg: "/images/animations/gcard.svg",
    icon: "/images/animations/ill10.svg",
    link: "/page-2",
  },
  {
    title: "DesignSystem",
    bg: "/images/animations/gcard.svg",
    icon: "/images/animations/ill10.svg",
    link: "/page-2",
  },
  {
    title: "DesignSystem",
    bg: "/images/animations/gcard.svg",
    icon: "/images/animations/ill10.svg",
    link: "/page-2",
  },
  {
    title: "DesignSystem",
    bg: "/images/animations/gcard.svg",
    icon: "/images/animations/ill10.svg",
    link: "/page-2",
  },
  {
    title: "DesignSystem",
    bg: "/images/animations/gcard.svg",
    icon: "/images/animations/ill10.svg",
    link: "/page-2",
  },
]

export default menuData
